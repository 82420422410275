import { MATERIAL_TYPES } from "constants/material";

export const RULES = {
  [MATERIAL_TYPES.ROCK]: {
    beats: [MATERIAL_TYPES.SCISSOR, MATERIAL_TYPES.LIZARD],
  },
  [MATERIAL_TYPES.PAPER]: {
    beats: [MATERIAL_TYPES.ROCK, MATERIAL_TYPES.SPOCK],
  },
  [MATERIAL_TYPES.SCISSOR]: {
    beats: [MATERIAL_TYPES.PAPER, MATERIAL_TYPES.LIZARD],
  },
  [MATERIAL_TYPES.LIZARD]: {
    beats: [MATERIAL_TYPES.SPOCK, MATERIAL_TYPES.PAPER],
  },
  [MATERIAL_TYPES.SPOCK]: {
    beats: [MATERIAL_TYPES.SCISSOR, MATERIAL_TYPES.ROCK],
  },
};
